.siloProduct {
  @extend .box;
  height: 42px;
  color: $grain-color;
  padding: 5px;
  position: relative;
  overflow: hidden;
  h2 {
    font-size: 20px;
    width: 100%;
    .glyphicon {
      font-size: 50px;
      margin-left: -25px;
      position: absolute;
    }
    .grainName {
      position: absolute;
      width: 100%;
      text-align: center;
    }
  }
  button {
    color: $grain-color;
    position: absolute;
    right: 5px;
    bottom: 5px;
  }
  select {
    font-size: 16px;
    color: $grain-color;
    border-color: $grain-color;
    margin: 0;
  }
  &WithoutCables {
    height: 194px;
    h1 {
      color: $text-color;
      width: 100%;
      text-align: center;
      font-size: 18px;
      padding: 10px 0;
    }
    h2 {
      font-size: 20px;
      margin-left: -3px;
      .glyphicon {
        font-size: 90px;
        top: 110px;
        left: 10px;
        position: absolute;
      }
    }
    a {
      right: 15px;
      bottom: 20px;
      font-size: 24px;
    }
  }
}

//Smartphones to Phablets
@media (max-width: 719px){
  .siloProduct {
    height: 194px;
    h1 {
      color: $text-color;
      width: 100%;
      text-align: center;
      font-size: 18px;
      padding: 10px 0;
    }
    h2 {
      font-size: 40px;
      .glyphicon {
        font-size: 90px;
        top: 110px;
        left: 10px;
        position: absolute;
      }
    }
    a {
      right: 15px;
      bottom: 20px;
      font-size: 24px;
    }
  }
}
