html {
  background: $background-color-end;
  min-width: 100%;
  height: 100%;
  position: relative;
}
body {
  @include background-gradient($background-color, $background-color-end);
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: $text-color;
  min-width: 100%;
  min-height: 100%;
  position: relative;
  a {
    color: $text-color;
    text-decoration: none;
  }
}

//Text
.text-info    { color: $info-color !important; }
.text-success { color: $success-color !important; }
.text-warning { color: $warning-color !important; }
.text-danger,
.text-error   { color: $danger-color !important; }
.text-primary { color: $primary-color !important; }
.text-default { color: $default-color !important; }
.text-dark    { color: $background-color-end !important; }

.vertSep {
  border-left: 1px solid $text-color;
  opacity: .4;
  display: inline;
}

hr {
  border: none;
  border-bottom: 1px solid $text-color;
  opacity: .4;
  margin: 15px 0 0 0;
}

//Background
.bg-info    { background: $info-color !important; }
.bg-success { background: $success-color !important; }
.bg-warning { background: $warning-color !important; }
.bg-danger,
.bg-error   { background: $danger-color !important; }
.bg-primary { background: $primary-color !important; }
.bg-default { background: $default-color !important; }
.bg-grain   { background: $grain-color !important; }
.bg-box     { background: $box-color !important; }

//Border and outlines
.outline-info,
.outline-success,
.outline-warning,
.outline-danger,
.outline-primary,
.outline-default {
  transition: box-shadow .3s;
  -o-transition: box-shadow .3s;
  -ms-transition: box-shadow .3s;
  -moz-transition: box-shadow .3s;
  -webkit-transition: box-shadow .3s;
}
.outline-info    { box-shadow: 0 0 0 5px $info-color; }
.outline-success { box-shadow: 0 0 0 5px $success-color; }
.outline-warning { box-shadow: 0 0 0 5px $warning-color; }
.outline-danger  { box-shadow: 0 0 0 5px $danger-color; }
.outline-primary { box-shadow: 0 0 0 5px $primary-color; }
.outline-default { box-shadow: 0 0 0 5px $default-color; }

//Buttons and Controls
.btn, .btn.btn-default, .no-btn,
.ctr, .ctr.ctr-default, .no-ctr  {
  @include border-radius(5px);
  @include box-sizing(border-box);
  position: relative;
  font-family: inherit;
  font-size: inherit;
  background: none;
  border: 2px solid $default-color;
  color: $default-color;
  text-align: center;
  line-height: 100%;
  padding: 7px 10px;
  display: inline-block;
  cursor: pointer;
  &[disabled] {
    cursor: not-allowed;
    opacity: .5;
  }
}
.btn.btn-info,
.ctr.ctr-info {
  border: 2px solid $info-color;
  color: $info-color;
}
.btn.btn-success,
.ctr.ctr-success {
  border: 2px solid $success-color;
  color: $success-color;
}
.btn.btn-warning,
.ctr.ctr-warning {
  border: 2px solid $warning-color;
  color: $warning-color;
}
.btn.btn-danger,
.btn.btn-error,
.ctr.ctr-danger,
.ctr.ctr-error {
  border: 2px solid $danger-color;
  color: $danger-color;
}
.btn.btn-primary,
.ctr.ctr-primary {
  border: 2px solid $primary-color;
  color: $primary-color;
}
.btn.btn-box,
.ctr.ctr-box {
  border: 2px solid $box-color;
  color: $box-color;
}
.btn.btn-grain,
.ctr.ctr-grain {
  border: 2px solid $grain-color;
  color: $grain-color;
}
.btn-xbig,
.ctr-xbig {
  display: block;
  width: 100%;
  .glyphicon {
    font-size: 3em;
  }
}
.btn-xbig-inline {
  .glyphicon {
    font-size: 3em;
  }
}
.no-btn,
.no-ctr {
  @include border-radius(none);
  border: none;
  color: inherit;
  padding: 0;
}
.ctr {
  margin: 4px 0;
}

//Forms and Inputs
.ipt {
  @include border-radius(5px);
  @include box-sizing(border-box);
  @include box-shadow(1px, 1px, 1px, rgba(0,0,0,.4), inset);
  background: #FFF;
  border: none;
  padding: 5px;
  font-family: inherit;
  font-size: inherit;
  display: block;
  width: 100%;
  &-big {
    font-size: 2em;
    font-weight: lighter;
  }
  &-inline {
    display: inline-block;
    margin-left: 5px;
    width: auto;
  }
}
input[type='number'] {
  -moz-appearance:textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.form {
  p {
    margin-bottom: 10px;
  }
  p.fRight, p.fLeft {
    width: 48%;
  }
}

//Labels
.circle-label {
  @include border-radius(12px);
  @include box-sizing(border-box);
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: #FFF;
  &-topRight {
    position: absolute;
    right: -10px;
    top: -10px;
  }
}

//Text
.genTitle {
  font-size: 32px;
  padding: 15px 0;
  font-weight: lighter;
}
.genTitle2 {
  font-size: 24px;
  font-weight: bold;
}
.genTitle3 {
  font-size: 22px;
}
.genTitle4 {
  font-size: 20px;
}
.genTitle5 {
  font-size: 12px;
}
//Table
table {
  overflow: hidden;
  width: 100%;
  margin: 15px 0;
  tr:hover {
    background: $box-color;
  }
  th, td {
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, .2);
  }
  .outLevel {
    opacity: .5;
  }
}

//Transitions
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
  -o-transition: opacity .2s;
  -ms-transition: opacity .2s;
  -moz-transition: opacity .2s;
  -webkit-transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active,
.slideLeft-fade-enter-active,
.slideDown-fade-enter-active,
.slide-fade-0-enter-active,
.zoom-fade-enter-active,
.zoom-fade-0-enter-active {
  transition: all .3s ease;
  -o-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -webkit-transition: all .3s ease;
}
.slide-fade-leave-active,
.slideLeft-fade-enter-active,
.slideDown-fade-enter-active,
.slide-fade-0-leave-active,
.zoom-fade-leave-active,
.zoom-fade-0-leave-active {
  transition: all .2s ease;
  -o-transition: all .2s ease;
  -ms-transition: all .2s ease;
  -moz-transition: all .2s ease;
  -webkit-transition: all .2s ease;
}
.slide-fade-enter,
.slide-fade-leave-to,
.slide-fade-0-enter,
.slide-fade-0-leave-to {
  transform: translateX(10px);
  -o-transform: translateX(10px);
  -ms-transform: translateX(10px);
  -moz-transform: translateX(10px);
  -webkit-transform: translateX(10px);
  opacity: 0;
}

.zoom-fade-enter,
.zoom-fade-leave-to,
.zoom-fade-0-leave-to {
  transform: scale(.5);
  -o-transform: scale(.5);
  -ms-transform: scale(.5);
  -moz-transform: scale(.5);
  -webkit-transform: scale(.5);
  opacity: 0;
}

.slideDown-fade-enter,
.slideDown-fade-leave-to {
  transform: translateY(-10px);
  -o-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
  opacity: 0;
}

.slideLeft-fade-enter,
.slideLeft-fade-leave-to {
  transform: translateX(-10px);
  -o-transform: translateX(-10px);
  -ms-transform: translateX(-10px);
  -moz-transform: translateX(-10px);
  -webkit-transform: translateX(-10px);
  opacity: 0;
}

@for $i from 1 through 30 {
  .slide-fade-#{$i}-enter-active, .zoom-fade-#{$i}-enter-active {
    transition: all .3s ease $i * 0.15s;
    -o-transition: all .3s ease $i * 0.15s;
    -ms-transition: all .3s ease $i * 0.15s;
    -moz-transition: all .3s ease $i * 0.15s;
    -webkit-transition: all .3s ease $i * 0.15s;
  }
  .slide-fade-#{$i}-leave-active, .zomm-fade-#{$i}-leave-active {
    transition: all .2s ease $i * 0.15s;
    -o-transition: all .2s ease $i * 0.15s;
    -ms-transition: all .2s ease $i * 0.15s;
    -moz-transition: all .2s ease $i * 0.15s;
    -webkit-transition: all .2s ease $i * 0.15s;
  }
  .slide-fade-#{$i}-enter,
  .slide-fade-#{$i}-leave-to,
  .zoom-fade-#{$i}-enter,
  .zoom-fade-#{$i}-leave-to {
    transform: scale(.5);
    -o-transform: scale(.5);
    -ms-transform: scale(.5);
    -moz-transform: scale(.5);
    -webkit-transform: scale(.5);
    opacity: 0;
  }
}

@keyframes rotateAll {
  0%   { transform: rotate(0); }
  100% { transform: rotate(359.9deg); }
}
