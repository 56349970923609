.subHeader {
  padding: 15px 0;
  h1 {
    font-size: 30px;
    font-weight: lighter;
  }
  h3 {
    font-size: 20px;
    font-weight: bold;
  }
}

//Smartphones to Phablets
@media (max-width: 719px) {
  .subHeader {
    margin-top: 50px;
    h1 {
      font-size: 20px;
    }
    h3 {
      font-size: 16px;
    }
  }
}

//Small Tablets
@media (min-width: 720px) and (max-width: 959px) {
  .subHeader {
    h1 {
      font-size: 24px;
    }
  }
}

//Tablets to Notebooks
@media (min-width: 960px) and (max-width: 1439px) {
  .subHeader {
    h1 {
      font-size: 26px;
    }
  }
}

