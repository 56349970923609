.siloValidationDesc {
  @extend .box;
  height: 91px;
  overflow: hidden;
  position: relative;
  font-size: 18px;
  margin-bottom: 10px;
  div {
    position: absolute;
    display: table;
    width: 100%;
    height: 100%;
    p {
      padding: 0 10px;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
    }
  }
}

//Phablets to Small Tablets
@media (min-width: 480px) and (max-width: 959px) {
  .siloValidationDesc {
    margin-top: 10px;
  }
}

//Small Tablets
@media (min-width: 720px) and (max-width: 959px) {
  .siloValidationDesc {
    font-size: 26px;
  }
}
