.siloHumidity {
  @extend .box;
  height: 194px;
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
  h1 {
    @include box-sizing(border-box);
    text-align: center;
    padding: 10px 0;
    font-size: 18px;
  }
  .siloHumidity-chart {
    width: 90%;
    height: 100px !important;
    margin: 0 auto;
  }
  h2 {
    position: absolute;
    font-size: 36px;
    font-weight: lighter;
    top: 74px;
    line-height: 100%;
    width: 100%;
    text-align: center;
    sup {
      font-size: 14px;
    }
  }
  p {
    font-size: 16px;
    padding-top: 14px;
    width: 100%;
    text-align: center;
    word-wrap: break-word;
    line-height: 100%;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
  button {
    @include box-sizing(border-box);
    position: absolute;
    padding-right: 10px;
    width: 100%;
    bottom: 45px;
  }
}
