.sensorAeration {
  @extend .box;
  padding: 15px 10px;
  font-size: 18px;
  overflow: hidden;
  h1 {
    position: relative;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 24px;
    line-height: 1;
    margin-top: 5px;
    width: 200%;
  }
}

@media screen and (max-width: 719px){
  .sensorAeration {
    font-size: 16px;
    padding: 12px 5px;
    h1 {
      font-size: 22px;
    }
  }
}
