.siloRef {
  @extend .box;
  display: table;
  width: 100%;
  height: 100%;
  padding: 10px;
  margin-bottom: 20px;
  &WithoutCables {
    height: auto;
    .siloRef-map {
      padding: 0;
      text-align: center;
      background: none;
      &:before {
        display: none;
      }
      margin-top: 50px;
    }
  }
  h1 {
    text-transform: uppercase;
    font-size: 23px;
    font-weight: bold;
  }
  &-show {
    text-align: center;
    display: block;
    padding-top: 5px;
  }
  &-mapContainer {
    width: 100%;
    max-width: 250px;
    display: block;
    margin: 0 auto;
  }
  &-map {
    background: $text-color;
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    margin-top: 70px;
    a {
      @include border-radius(50%);
      background: $box-color;
      color: $text-color;
      display: block;
      position: absolute;
      font-size: 23px;
      font-weight: bold;
      text-align: center;
      line-height: 35px;
      width: 36px;
      height: 36px;
    }
    &:before {
      position: absolute;
      content: "Frente";
      text-align: center;
      width: 50px;
      font-size: 14px;
      left: 50%;
      margin-left: -25px;
      top: -28px;
      border-bottom: 10px solid $text-color;
    }
    &-reverseMap:before {
      content: "Fundo";
    }
    &-layout-0 { //Silo comum
      @include border-radius(50%);
    }
    &-cable {
      transition: all .2s ease;
      -o-transition: all .2s ease;
      -ms-transition: all .2s ease;
      -moz-transition: all .2s ease;
      -webkit-transition: all .2s ease;
      &.bg-info {
        background: $info-color;
      }
      &.outProduct {
        opacity: 0.5;
      }
    }
    &-cables-1 {
      .siloRef-map-cable-0 {
        left: 50%;
        top: 50%;
        margin: -18px;
      }
    }
    //Layout dos cabos para silo comum
    &-cables-2.siloRef-map-layout-0 {
      .siloRef-map-cable-0 {
        left: 50%;
        top: 10px;
        margin-left: -18px;
      }
      .siloRef-map-cable-1 {
        bottom: 10px;
        left: 50%;
        margin-left: -18px;
      }
    }
    &-cables-3.siloRef-map-layout-0 {
      .siloRef-map-cable-0 {
        left: 50%;
        top: 25px;
        margin-left: -18px;
      }
      .siloRef-map-cable-1 {
        top: 70%;
        right: 20%;
        margin: -18px;
      }
      .siloRef-map-cable-2 {
        left: 20%;
        top: 70%;
        margin: -18px;
      }
    }
    &-cables-4.siloRef-map-layout-0 {
      .siloRef-map-cable-0 {
        left: 50%;
        top: 20px;
        margin-left: -18px;
      }
      .siloRef-map-cable-1 {
        right: 20%;
        top: 70%;
        margin: -18px;
      }
      .siloRef-map-cable-2 {
        top: 70%;
        left: 20%;
        margin: -18px;
      }
      .siloRef-map-cable-3 {
        left: 50%;
        top: 50%;
        margin: -18px;
      }
    }
    &-cables-5.siloRef-map-layout-0 {
      .siloRef-map-cable-0 {
        left: 50%;
        top: 10px;
        margin-left: -18px;
      }
      .siloRef-map-cable-1 {
        right: 10px;
        top: 50%;
        margin-top: -18px;
      }
      .siloRef-map-cable-2 {
        bottom: 10px;
        left: 50%;
        margin-left: -18px;
      }
      .siloRef-map-cable-3 {
        left: 10px;
        top: 50%;
        margin-top: -18px;
      }
      .siloRef-map-cable-4 {
        left: 50%;
        top: 50%;
        margin: -18px;
      }
    }
    &-cables-6.siloRef-map-layout-0 {
      .siloRef-map-cable-0 {
        left: 50%;
        top: 10px;
        margin-left: -18px;
      }
      .siloRef-map-cable-1 {
        left: 85%;
        top: 35%;
        margin: -18px;
      }
      .siloRef-map-cable-2 {
        left: 75%;
        top: 80%;
        margin: -18px;
      }
      .siloRef-map-cable-3 {
        left: 25%;
        top: 80%;
        margin: -18px;
      }
      .siloRef-map-cable-4 {
        left: 15%;
        top: 35%;
        margin: -18px;
      }
      .siloRef-map-cable-5 {
        left: 50%;
        top: 50%;
        margin: -18px;
      }
    }
    &-cables-7.siloRef-map-layout-0 {
      .siloRef-map-cable-0 {
        left: 50%;
        top: 10px;
        margin-left: -18px;
      }
      .siloRef-map-cable-1 {
        left: 85%;
        top: 35%;
        margin: -18px;
      }
      .siloRef-map-cable-2 {
        left: 80%;
        top: 70%;
        margin: -18px;
      }
      .siloRef-map-cable-3 {
        left: 50%;
        bottom: 30px;
        margin: -18px;
      }
      .siloRef-map-cable-4 {
        left: 20%;
        top: 70%;
        margin: -18px;
      }
      .siloRef-map-cable-5 {
        left: 15%;
        top: 35%;
        margin: -18px;
      }
      .siloRef-map-cable-6 {
        left: 50%;
        top: 50%;
        margin: -18px;
      }
    }
    &-cables-9.siloRef-map-layout-0 {
      .siloRef-map-cable-0 {
        left: 50%;
        top: 10px;
        margin-left: -18px;
      }
      .siloRef-map-cable-1 {
        right: 15px;
        top: 35%;
      }
      .siloRef-map-cable-2 {
        right: 30%;
        bottom: 45px;
        margin-right: -45px;
      }
      .siloRef-map-cable-3 {
        left: 35%;
        bottom: 45px;
        margin-left: -60px;
      }
      .siloRef-map-cable-4 {
        left: 15px;
        top: 35%;
      }
      .siloRef-map-cable-5 {
        left: 50%;
        top: 25%;
        margin-left: -18px;
      }
      .siloRef-map-cable-6 {
        right: 80px;
        bottom: 30%;
        top: auto;
      }
      .siloRef-map-cable-7 {
        left: 80px;
        right: auto;
        bottom: 30%;
      }
      .siloRef-map-cable-8 {
        left:50%;
        top: 50%;
        margin:-18px;
      }
    }
    &-cables-18.siloRef-map-layout-0 {
      .siloRef-map-cable-0 {
        left: 60%;
        top: 10px;
        margin-left: -18px;
      }
      .siloRef-map-cable-1 {
        right: 45px;
        top: 15%;
      }
      .siloRef-map-cable-2 {
        right: 15px;
        top: 32%;
      }
      .siloRef-map-cable-3 {
        right: 15px;
        bottom: 32%;
      }
      .siloRef-map-cable-4 {
        right: 45px;
        bottom: 15%;
      }
      .siloRef-map-cable-5 {
        left: 60%;
        bottom: 10px;
        margin-left: -18px;
      }
      .siloRef-map-cable-11 {
        right: 60%;
        top: 10px;
        margin-right: -18px;
      }
      .siloRef-map-cable-10 {
        left: 45px;
        top: 15%;
      }
      .siloRef-map-cable-9 {
        left: 15px;
        top: 32%;
      }
      .siloRef-map-cable-8 {
        left: 15px;
        bottom: 32%;
      }
      .siloRef-map-cable-7 {
        left: 45px;
        bottom: 15%;
      }
      .siloRef-map-cable-6 {
        right: 60%;
        bottom: 10px;
        margin-right: -18px;
      }
      .siloRef-map-cable-15 {
        left: 80px;
        top: 25%;
      }
      .siloRef-map-cable-12 {
        right: 80px;
        top: 25%;
      }
      .siloRef-map-cable-13 {
        right: 80px;
        bottom: 25%;
      }
      .siloRef-map-cable-14 {
        left: 80px;
        bottom: 25%;
      }
      .siloRef-map-cable-16 {
        left: 50%;
        top: 50%;
        margin-left: -18px;
        margin-top: -37px;
      }
      .siloRef-map-cable-17 {
        left: 50%;
        top: 50%;
        margin-left: -18px;
        margin-bottom: -40px;
      }
    }
    //Layout dos cabos para silo graneleiro
    &-layout-1 {
      padding: 10px 0;
      height: 100%;
      display: table;
      .siloRef-map-cable {
        position: relative;
        float: left;
        font-size: .9em;
        margin-top: 4px;
        margin-bottom: 4px;
        width: 25px;
        height: 25px;      
        line-height: 25px;  
      }
    }
    &-layout-1.siloRef-map-1-cablesPerLine {
      .siloRef-map-cable {
        float: none;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &-layout-1.siloRef-map-2-cablesPerLine {
      .siloRef-map-cable:nth-child(2n + 1) { //Primeiros cabos da linha
        clear: both;
        margin-left: 10%;
      }
      .siloRef-map-cable:nth-child(2n + 2) { //Segundos
        float: right;
        margin-right: 10%;
      }
    }
    &-layout-1.siloRef-map-3-cablesPerLine {
      .siloRef-map-cable:nth-child(3n + 1) { //Primeiros cabos da linha
        clear: both;
        margin-left: 10%;
      }
      .siloRef-map-cable:nth-child(3n + 2) { //Segundos
        margin-left: 40%;
        left: -37px;
      }
      .siloRef-map-cable:nth-child(3n + 3) { //Terceiros
        float: right;
        margin-right: 10%;
      }
    }
    &-layout-1.siloRef-map-4-cablesPerLine {
      .siloRef-map-cable:nth-child(4n + 1) { //Primeiros cabos da linha
        clear: both;
        margin-left: 10%;
      }
      .siloRef-map-cable:nth-child(4n + 2), .siloRef-map-cable:nth-child(4n + 3) { //Segundos e Terceiros
        margin-left: 15%;
      }
      .siloRef-map-cable:nth-child(4n + 4) { //Terceiros
        float: right;
        margin-right: 10%;
      }
    }
    &-layout-1.siloRef-map-5-cablesPerLine {
      .siloRef-map-cable {
        margin-left: 7%;
      }
      .siloRef-map-cable:nth-child(5n + 1) { //Primeiros cabos da linha
        clear: both;
      }
    }
  }
  .btn-update {
    min-width: 180px;
    display: table;
    margin: 15px auto 0 auto;
  }
}

//Small Tablets to TVs
@media (min-width: 720px) {
  .siloRef {
    height: 450px;
    &WithoutCables {
      height: auto;
    }
  }
}
