.row {
  @include clearfix;
  width: 100%;
  clear: both;
}

@for $i from 1 through 12 {
  .col-#{$i} {
    @include col($i, 12px);
  }
  .col-vw-#{$i} {
    @include col-vw($i, 12px);
  }
}

//Smartphones
@media (max-width: 479px) {
  @for $i from 1 through 12 {
    .col-#{$i},
    .row .col-sm-#{$i} {
      @include col($i, 5px);
    }

    .col-vw-#{$i},
    .row .col-vw-sm-#{$i} {
      @include col-vw($i, 5px);
    }
  }
}

//Phablets
@media (min-width: 480px) and (max-width: 719px) {
  @for $i from 1 through 12 {
    .col-#{$i},
    .row .col-ph-#{$i} {
      @include col($i, 8px);
    }

    .col-vw-#{$i},
    .row .col-vw-ph-#{$i} {
      @include col-vw($i, 8px);
    }
  }
}

//Small Tablets
@media (min-width: 720px) and (max-width: 959px) {
  @for $i from 1 through 12 {
    .col-#{$i},
    .row .col-st-#{$i} {
      @include col($i, 8px);
    }

    .col-vw-#{$i},
    .row .col-vw-st-#{$i} {
      @include col-vw($i, 8px);
    }
  }
}

//Tablets
@media (min-width: 960px) and (max-width: 1271px) {
  @for $i from 1 through 12 {
    .col-#{$i},
    .row .col-tb-#{$i} {
      @include col($i, 10px);
    }

    .col-vw-#{$i},
    .row .col-vw-tb-#{$i} {
      @include col-vw($i, 10px);
    }
  }
}

//Notebooks
@media (min-width: 1272px) and (max-width: 1439px) {
  @for $i from 1 through 12 {
    .col-#{$i},
    .row .col-nb-#{$i} {
      @include col($i, 12px);
    }

    .col-vw-#{$i},
    .row .col-vw-nb-#{$i} {
      @include col-vw($i, 12px);
    }
  }
}

//Desktops
@media (min-width: 1440px) and (max-width: 1919px) {
  @for $i from 1 through 12 {
    .col-#{$i},
    .row .col-dk-#{$i} {
      @include col($i, 12px);
    }

    .col-vw-#{$i},
    .row .col-vw-dk-#{$i} {
      @include col-vw($i, 12px);
    }
  }
}

//TVs and Large Desktops
@media (min-width: 1920px) {
  @for $i from 1 through 12 {
    .col-#{$i},
    .row .col-tv-#{$i} {
      @include col($i, 15px);
    }

    .col-vw-#{$i},
    .row .col-vw-tv-#{$i} {
      @include col-vw($i, 15px);
    }
  }
}

//Print
@media print {
  @for $i from 1 through 12 {
    .col-#{$i},
    .row .col-pt-#{$i} {
      @include col($i, 5px);
    }

    .col-vw-#{$i},
    .row .col-vw-pt-#{$i} {
      @include col-vw($i, 5px);
    }
  }
}

.row .col-offsetLeft {
  padding-left: 0;
}
.row .col-offsetRight {
  padding-right: 0;
}
