.timeline {
  margin: 15px 0;
  h3 {
    margin-bottom: 15px;
  }
  &-itemContainer {
    border-left: 12px solid;
    min-height: 5px;
    
    &.text-success { border-color: $success-color; }
    &.text-warning { border-color: $warning-color; }

    &:last-child .timeline-item:before {
      margin-top: 53px;
    }
  }
  &-item { 
    &:before {
      display: block;
      position: absolute;
      content: "";
      border-radius: 50%;
      width: 12px;
      height: 12px;
      border: 3px solid #DDD;
      margin: -3px -15px;
    }

    &-text {
      padding: 5px 15px 15px 10px;
    }
  }
}