.siloMode {
  .btn {
    padding: 9px 0 10px 0;
    font-size: 14px;
    line-height: 150%;
  }
}

//Smartphones to Tablets
@media (max-width: 1271px) {
  .siloMode {
    .btn {
      @include box-sizing(border-box);
      width: 100%;
      padding: 6px;
    }
  }
}
