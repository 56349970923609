.notification {
  @include border-radius(7px);
  @include box-sizing(border-box);
  display: block;
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  h1 {
    font-size: 20px !important;
    margin-bottom: 5px;
    font-weight: normal !important;
    text-transform: none !important;
  }
  .notification-info {
    @include border-radius(7px);
    background: rgba(0, 0, 0, .3);
    padding: 10px;
    margin-top: 5px;
  }
}
