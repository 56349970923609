.overMessage {
  position: fixed;
  width: 100%;
  height: 100%;
  font-size: 30px;
  font-weight: lighter;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(0, 43, 89, .8);
  z-index: 99999;
}
  .loading {
    @include border-radius(100%);
    border-style: solid;
    border-width: 5px;
    height: 50px;
    width: 50px;
    border-color: rgba(255, 255, 255, 0.2);
    border-top-color: rgba(255, 255, 255, 1);
    animation: rotateAll 1s infinite linear;
    margin: 15px auto;
  }
