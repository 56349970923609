.siloAerationType {
  @extend .box;
  padding: 15px 10px 10px 15px;
  font-size: 18px;
  overflow: hidden;
  h1 {
    position: relative;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 26px;
  }
}

//Smartphones to Phablets
@media (max-width: 719px){
  .siloAerationType {
    margin: 10px 0;
  }
}
