.sensorLastReading {
  @extend .box;
  padding: 15px 10px;
  font-size: 20px;
  overflow: hidden;
  margin-bottom: 20px;
  h1 {
    position: relative;
    text-transform: uppercase;
    font-weight: lighter;
    font-size: 52px;
    line-height: 1;
  }
}

@media screen and (max-width: 719px){
  .sensorLastReading {
    font-size: 16px;
    padding: 18px 5px;
    margin-bottom: 10px;
    h1 {
      font-size: 42px;
    }
  }
}
