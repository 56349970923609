.modal-wrapper {
  @include box-sizing(border-box);
  position: fixed;
  width: 100%;
  height: 100%;
  font-weight: lighter;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 43, 89, .6);
  z-index: 999;
  top: 0;
  padding: 10px;
  &.modal-login {
    z-index: 9999;
  }
}

.modal {
  @include border-radius(12px);
  @include box-shadow(0px, 0px, 100px, rgba(0, 0, 0, .2));
  width: 500px;
  max-width: 100%;
  overflow: hidden;
  &-title {
    padding: 5px;
    h1 {
      font-size: 24px;
      padding-left: 10px;
    }
  }
  &-body {
    @include box-sizing(border-box);
    background: $box-color;
    padding: 15px;
    max-height: 85vh;
    overflow: auto;
  }
}
