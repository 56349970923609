.weather {
  @extend .box;
  height: 70px;
  padding: 10px;
  overflow: hidden;
  position: relative;
  img {
    width: 22px;
    position: absolute;
  }
  .weather-values {
    padding-top: 25px;
    h1 {
      font-size: 30px;
      line-height: 100%;
      font-weight: lighter;
      sub {
        font-size: 12px;
        margin-left: -12px;
        vertical-align: baseline;
      }
    }
    h2 {
      font-size: 30px;
      font-weight: lighter;
      line-height: 100%;
      font-size: 24px;
      margin-left: 15px;
      sup {
        font-size: 10px;
      }
      sub {
        font-size: 10px;
        margin-left: -5px;
        vertical-align: baseline;
      }
    }
  }
}

//Small Tablets to TVs
@media (min-width: 720px) {
  .weather-today {
    img {
      width: auto;
      margin: -10px 10px 0 -55px;
    }
    .weather-values {
      padding: 0 30px 0 0;
      h1 {
        font-size: 50px;
        sub {
          font-size: 24px;
          margin-left: -18px;
        }
      }
      h2 {
        font-size: 30px;
        margin: 12px 0 0 20px;
        sup {
          font-size: 15px;
        }
        sub {
          font-size: 14px;
          margin-left: -12px;
        }
      }
    }
  }
}

.weather-date {
  position: absolute;
  right: 10px;
}
