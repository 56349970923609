@media print {
  $print-border-color: #999;
  $default-color: #000;

  html, body, .header, .weather, .siloRef, .siloCables-cable,
  .siloHumidity, .siloTemperature, .siloValidation, .siloProduct, .siloProduct h1,
  .siloRef-map, .siloMotors, .siloMotors p, .siloAerationType,
  .siloValidationDesc, .sensorInfo, .siloInfo {
    color: $default-color !important;
    background: #FFF !important;
  }
    body a { color: $default-color !important; }
    *.hide-pt {
      display: none !important;
    }
    *.show-pt {
      display: block !important;
    }
    hr {
      border-color: $print-border-color;
      margin: 5px 0;
    }
    .print-noBreak {
      page-break-inside: avoid;
    }
    .print-smallText {
      font-size: 12px;
    }
    .bg-info, .btn.btn-info, .ctr.ctr-info {
      background: none !important;
      color: $info-color !important;
    }
    .bg-success, .btn.btn-success, .ctr.ctr-success {
      background: none !important;
      color: $success-color !important;
    }
    .bg-warning, .btn.btn-warning, .ctr.ctr-warning {
      background: none !important;
      color: $warning-color !important;
    }
    .bg-danger, .bg-error, .btn.btn-danger, .ctr.ctr-danger, .btn.btn-error, .ctr.ctr-info {
      background: none !important;
      color: $danger-color !important;
    }
    .bg-primary, .btn.btn-primary, .ctr.ctr-primary {
      background: none !important;
      color: $warning-color !important;
    }
    .bg-default, .btn.btn-default, .ctr.ctr-default {
      background: none !important;
      color: $default-color !important;
    }
    .bg-grain, .btn.btn-grain, .ctr.ctr-grain   {
      background: none !important;
      color: $warning-color !important;
    }
    .btn.btn-info, .ctr.ctr-info, .btn.btn-success, .ctr.ctr-success, .btn.btn-warning,
    .ctr.ctr-warning, .btn.btn-danger, .btn.btn-error, .ctr.ctr-danger, .ctr.ctr-error,
    .btn.btn-primary, .ctr.ctr-primary {
      border: none;
    }

    table {
      margin: 0 0 10px 0 !important;
      td, th {
        border-bottom: 1px solid $print-border-color !important;
        padding: 5px 10px 5px 0;
      }
    }

    .header { padding: 5px 0 !important; }

    .subHeader {
      padding: 5px 0 0 0 !important;
      margin: 0 !important;
    }
      .client-title, .weather {
        width: auto !important;
        display: table !important;
      }
      .weather {
        float: right;
        margin-left: 25px;
        padding: 0;
      }

    .siloRef {
      padding: 0;
      height: auto !important;
    }

    .siloCables {
      padding: 0;
      overflow: visible;

      .siloCables-wrapper {
        width: 100% !important;
        overflow: visible;

        .siloCables-cable {
          border: 1px solid $print-border-color;
          margin-bottom: 15px;

          h1 {
            height: auto;
            padding: 2px 0;
          }

          .bg-info h1    { color: $info-color !important; }
          .bg-success h1 { color: $success-color !important; }
          .bg-warning h1 { color: $warning-color !important; }
          .bg-danger h1,
          .bg-error h1   { color: $danger-color !important; }
          .bg-primary h1 { color: $warning-color !important; }
          .bg-default h1 { color: $default-color !important; }
          .bg-grain h1   { color: $warning-color !important; }

          .siloCables-sensor {
            border-top: 1px solid $print-border-color;
          }

        }
      }
    }

    #siloHumidity-chart,
    #siloTemperature-chart,
    #siloValidation-chart {
      display: block;
      margin: 0 auto;
    }

    .siloRef-map {
      margin: 25px 0 15px 0 !important;
      border: 1px solid $print-border-color;

      &:before {
        color: $default-color;
        border-color: $print-border-color;
      }

      &-cable {
        color: $default-color !important;
        border: 1px solid $print-border-color !important;
        background: none !important;
      }
    }

    .siloProduct {
      height: 194px;
      padding-top: 10px;
      h1 {
        padding: 0 !important;
        color: $text-color;
        width: 100%;
        text-align: center;
        font-size: 18px;
      }
      h2 {
        font-size: 32px;
        margin: 0 auto 25px auto;
        text-align: center;
        .grainName {
          position: relative;
        }
      }
      a {
        right: 15px;
        bottom: 20px;
        font-size: 24px;
      }
    }

    .siloAerationType, .siloMotors {
      padding-top: 10px !important;
      text-align: center;
      margin: 0 !important;
    }

    .siloValidationDesc {
      margin: 15px 0 0 0;
      height: auto;
      font-size: 16px;
      overflow: visible;
      orphans: 3;
      widows: 3;
      div {
        display: block !important;
        position: relative;
        height: auto;
        p {
          @include box-sizing(border-box);
          width: 33.33% !important;
          display: block;
          float: left;
        }
      }
    }

    .sensorLastReading, .sensorAeration, .sensorInfo, .siloInfo {
      text-align: center;
      border: 1px solid $print-border-color;
      height: 95px;
      padding-left: 0;
      padding-right: 0;
      h1 {
        float: none;
        width: 100%;
        text-align: center;
        white-space: nowrap;
      }
    }

    .sensorLastReading {
      display: flex;
      justify-content: center;
      align-items: center;
      p, h1 {
        width: 50%;
      }
    }

    .timeline-itemContainer {
      min-height: 0;
    }

}
