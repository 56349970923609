.siloCables {
  overflow-x: auto;
  padding: 8px 0 5px 0;
  margin: -8px 0 0 0;

  &::-webkit-scrollbar {
    background: rgba(221, 221, 221, .15);
    height: 7px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, .25);
  }
  &-cable {
    @extend .box;
    overflow: hidden;
    transition: box-shadow .3s;
    -o-transition: box-shadow .3s;
    -ms-transition: box-shadow .3s;
    -moz-transition: box-shadow .3s;
    -webkit-transition: box-shadow .3s;
    h1 {
      @include box-sizing(border-box);
      height: 50px;
      text-align: center;
      padding: 15px 0 10px 0;
      font-size: 18px;
      width: 100%;
    }
    &.outProduct {
      opacity: .6;
      .siloCables-sensor {
        opacity: .3;
      }
    }
  }
  &-sensor {
    @include box-sizing(border-box);
    display: block;
    position: relative;
    transition: all .2s;
    -o-transition: all .2s;
    -ms-transition: all .2s;
    -moz-transition: all .2s;
    -webkit-transition: all .2s;
    h1 {
      //text-shadow: 1px 1px 1px rgba(0, 0, 0, .5);
      color: $background-color;
      font-size: 30px;
      height: 30px;
      padding: 0;
      line-height: 100%;
      width: 100%;
      //font-weight: lighter;
      position: absolute;
      top: 50%;
      margin-top: -16px;
      &.off {
        color: $default-color;
        font-weight: bold;
        text-transform: uppercase;
        text-shadow: none;
        font-size: 22px;
        margin-top: -12px;
      }
    }
    &.outLevel {
      opacity: .35;
    }
    &.bg-default {
      .glyphicon {
        color: $box-color;
      }
    }
    .glyphicon {
      position: absolute;
      top: 100%;
      right: 5px;
      margin-top: -20px;
    }
  }
}

//Small Tablets
@media (min-width: 720px) and (max-width: 959px) {
  .siloCables {
    &-sensor {
      h1 {
        font-size: 22px;
        height: 22px;
        margin-top: -11px;
        &.off {
          font-size: 16px;
          margin-top: -8px;
        }
      }
    }
  }
}

//TVs
@media (min-width: 1920px) {
  .siloCables {
    &-sensor {
      h1 {
        font-size: 30px;
        height: 40px;
        margin-top: -15px;
      }
    }
  }
}
