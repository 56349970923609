//Print
*.show-pt {
  display: none !important;
}

//Smartphones
@media screen and (max-width: 479px) {
  *.hide-sm {
    display: none !important;
  }
}

//Phablets
@media screen and (min-width: 480px) and (max-width: 719px) {
  *.hide-ph {
    display: none !important;
  }
}

//Small Tablets
@media screen and (min-width: 720px) and (max-width: 959px) {
  *.hide-st {
    display: none !important;
  }
}

//Tablets
@media screen and (min-width: 960px) and (max-width: 1271px) {
  *.hide-tb {
    display: none !important;
  }
}

//Notebooks
@media screen and (min-width: 1272px) and (max-width: 1439px) {
  *.hide-nb {
    display: none !important;
  }
}

//Desktops
@media screen and (min-width: 1440px) and (max-width: 1919px) {
  *.hide-dk {
    display: none !important;
  }
}

//TVs and Large Desktops
@media screen and (min-width: 1920px) {
  *.hide-tv {
    display: none !important;
  }
}
