.siloLevel {
  @extend .box;
  overflow: hidden;
  margin-bottom: 20px;
  h1 {
    text-align: center;
    padding: 10px 0;
    font-size: 18px;
    @include box-sizing(border-box);
    height: 50px;
  }
  &-stage {
    display: block;
    position: relative;
    width: 100%;
    .btn {
      @include box-sizing(border-box);
      display: block;
      width: 80%;
      position: absolute;
      top: 50%;
      height: 24px;
      margin: -15px 10% 0 10%;
      border: none;
      background: $background-color;
      color: $info-color;
      font-size: 18px;
      padding: 0;
      transition: all .2s;
      -o-transition: all .2s;
      -ms-transition: all .2s;
      -moz-transition: all .2s;
      -webkit-transition: all .2s;
      &.active {
        background: $grain-color;
        color: $box-color;
      }
    }
  }
}

//Small Tablets
@media (min-width: 720px) and (max-width: 959px) {
  .siloLevel {
    &-stage {
      .btn {
        font-size: 14px;
      }
    }
  }
}
