.popover {
  @include border-radius(7px);
  @include box-sizing(border-box);
  padding: 10px 0;
  position: absolute;
  background: $background-color;
  z-index: 99;
  background: rgba(255, 255, 255, .4);
  width: 320px;
  max-width: 100%;
  margin: 10px 0 0 10px;
  &:before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border: 15px solid transparent;
    border-bottom-color: rgba(255, 255, 255, .4);
    top: -30px;
    left: 15px;
  }
  .popover-content {
    @include box-sizing(border-box);
    padding: 0 10px;
    max-width: 320px;
    max-height: 340px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      background: rgba(221, 221, 221, .15);
      width: 7px;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, .25);
    }
  }
}

//Smartphones to Phablets
@media (max-width: 719px){
  .popover {
    right: 0;
  }
}
