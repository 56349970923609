.siloPower {
  .btn {
    padding: 5px 0;
    line-height: 150%;
  }
}

//Smartphones to Tablets
@media (max-width: 1271px) {
  .siloPower {
    .btn {
      @include box-sizing(border-box);
      width: 100%;
      padding: 6px;
      margin-bottom: 9px;
    }
  }
}
