.header {
  @include background-gradient-horizontal($background-color, #002B59);
  padding: 15px 0;
  .logo {
    height: 100%;
    img {
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      max-width: 280px;
    }
  }
  .header-menu {
    .btn, .vertSep, .user {
      margin-left: 15px;
    }
  }
}

//Smartphones to Phablets
@media (max-width: 719px){
  .header {
    .header-menu {
      .vertSep, .btn, .user {
        margin-left: 5px;
      }
    }
  }
}

//Small Tablets to Tablets
@media (min-width: 720px) and (max-width: 959px){
  .header {
    .header-menu {
      .vertSep, .btn, .user {
        margin-left: 10px;
      }
    }
  }
}

//Smartphones to Notebooks
@media (max-width: 1439px) {
  .header {
    padding: 10px 0;
  }
}
