.motor-starting .motor-icon-starting, .motor-starting .motor-icon-stopped {
  @include rotateAnimation(30s, infinite, linear);
}
.motor-stopping .motor-icon-stopping, .motor-stopping .motor-icon-started {
  @include rotateAnimation(8s, infinite, linear);
}
.motor-started .motor-icon-started, .motor-started .motor-icon-starting {
  @include rotateAnimation(3s, infinite, linear);
}

.motor-icon {
  transition: 5s;
  opacity: 0;
  position: absolute;
  right: 0;
}
.motor-starting .motor-icon-starting,
.motor-started .motor-icon-started,
.motor-stopping .motor-icon-stopping,
.motor-stopped .motor-icon-stopped,
.motor-unknow .motor-icon-unknow {
  opacity: 1;
}

.siloMotors {
  @extend .box;
  padding: 15px 10px 10px 15px;
  font-size: 18px;
  overflow: hidden;
  h1 {
    position: relative;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 24px;
  }
  img {
    position: absolute;
    left: 180px;
    top: -37px;
  }
}

//Notebooks to TVs
@media (min-width: 1272px){
  .siloMotors {
    img {
      left: auto;
      right: 0;
    }
  }
}

.siloMotors-hoists {
  .btn-xbig-inline {
    width: 105px;
    margin: 6px;
    text-align: center;
  }
}