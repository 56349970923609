.sidebar {
  position: absolute;
  z-index: 15;
  background: $box-color;
  font-size: 15px;
  .openMenu {
    display: block;
    padding: 10px;
  }
  .menu {
    > ul {
      > li {
        > a {
          padding: 20px;
          font-weight: bold;
          text-transform: uppercase;
        }
        a {
          display: block;
          line-height: 100%;
        }
        ul {
          li {
            a {
              @include background-gradient(#002853, #00234A);
              padding: 15px 20px 15px 40px;
            }
          }
        }
      }
    }
  }
  &-fake {
    z-index: -1;
    background: $box-color;
    height: 100vh;
    position: fixed;
    top: 0;
  }
}

//Smartphones to Phablets
@media (max-width: 719px) {
  .sidebar {
    .menu {
      margin-top: 10px;
      padding: 0 10px 10px 10px;
      line-height: 100%;
      > ul {
        float: right;
        > li {
          float: left;
          margin-left: 25px;
          .glyphicon.fRight {
            margin-left: 10px;
          }
          a {
            padding: 0 0 10px 0;
          }
        }
      }
    }
    .menu-mobile {
      ul {
        box-shadow: 0 5px 5px rgba(0, 0, 0, .4);
        li {
          a {
            display: block;
            @include background-gradient(#002853, #00234A);
            padding: 10px;
          }
        }
      }
    }
  }
}

//Smartphones to Tablets
@media (max-width: 1271px) {
  .sidebar {
    &.inactive {
      .menuItem-name, li ul, .glyphicon.glyphicon-chevron-up.fRight {
        display: none;
      }
      .glyphicon.glyphicon-chevron-down.fRight{
        display: inline-block !important;
      }
    }
  }
}

//Small Tablets to Tablets
@media (min-width: 720px) and (max-width: 1271px) {
  .sidebar {
    &.active {
      width: 250px;
    }
    .menu {
      > ul {
        > li {
          > a {
            padding: 20px 10px;
          }
          ul {
            li {
              a {
                padding: 15px 20px;
              }
            }
          }
        }
      }
    }
  }
}

//Small Tablets to TVs
@media (min-width: 720px) {
  .sidebar {
    min-height: 100%;
    min-height: calc(100% - 55px);
    .menu {
      > ul {
        > li {
          border-bottom: 1px solid rgba(238, 238, 238, .3);
          ul {
            li:last-child {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}

//Desktops to TVs
@media (min-width: 1440px) {
  .sidebar {
    min-height: 100%;
    min-height: calc(100% - 65px);
  }
}

//Small Tablets
@media (min-width: 720px) and (max-width: 959px) {
  .sidebar {
    .openMenu {
      text-align: center;
      .glyphicon {
        font-size: 3em;
        display: block;
        margin: 0 auto;
      }
    }
  }
}
