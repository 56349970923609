.fLeft {
  float: left;
}
.fRight {
  float: right;
}
.clear {
  @include clearfix;
  clear: both;
}
.text-center {
  text-align: center;
  th, td {
    text-align: center;
  }
}
.text-left {
  text-align: left;
  th, td {
    text-align: left;
  }
}
.text-right {
  text-align: right;
  th, td {
    text-align: right;
  }
}

.box {
  background: $box-color;
  @include border-radius(7px);
  @include box-sizing(border-box);
}

main {
  position: absolute;
  width: 100%;
}
